import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.map.js";
import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import DataLoading from "@/components/Loading/DataLoading";
import CalendarMixin from "@/mixins/Calendar";
import ReviewManagementMixin from "@/mixins/ReviewManagement";
import HasTokens from "@/components/HasTokensReviewManagement.vue";
import ScriptsTable from "@/components/ReviewManagement/ScriptsTable.vue";
import AppSelectMulti from "@/components/AppSelectMulti.vue";
export default {
  mixins: [CalendarMixin, ReviewManagementMixin],
  meta: {
    title: "Анализ и работа с отзывами"
  },
  data() {
    const localStorageCalendar = JSON.parse(localStorage.getItem("reviewManagementCalendar"));
    if (localStorageCalendar) {
      this.$store.dispatch("reviewManagement/updateCalendar", localStorageCalendar);
    }
    return {
      calendarInternal: null,
      calendar: this.$store.state.reviewManagement.calendar,
      totalScripts: 0
    };
  },
  computed: {
    tableKey() {
      return `${this.tabs_tables_model.id}`;
    },
    reportSettings() {
      const tokens = this.selectedTokens.map(item => item.id);
      if (!this.calendar) {
        return null;
      }
      if (!tokens.length) {
        return null;
      }
      const dates = [this.calendar[0], this.calendar[1]];
      return {
        date: dates[0],
        date2: dates[1],
        tokens
      };
    }
  },
  watch: {
    calendar() {
      this.$store.dispatch("reviewManagement/updateCalendar", this.calendar);
      localStorage.setItem("reviewManagementCalendar", JSON.stringify(this.calendar));
    }
  },
  methods: {
    addScript() {
      const limit = this.$access.getLimit("reviewManagement");
      if (this.totalScripts >= limit) {
        this.$modal.open(() => import("@/components/modals/access/ModalLimitReachedTemplate"), {
          alert: "Превышен лимит на вашем тарифном плане",
          title: "Исчерпан лимит сценариев. Для увеличения лимита необходимо перейти на старший тариф"
        });
      } else {
        this.$router.push({
          name: "ReviewManagementScript"
        });
      }
    },
    updateTotal(val) {
      this.totalScripts = val;
    }
  },
  components: {
    MainTitle,
    ReportPage,
    HasTokens,
    DataLoading,
    ScriptsTable,
    AppSelectMulti
  }
};